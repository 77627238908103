import { Box, Button } from '@mui/material';
import { useLayoutEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'utils/object';

type CancelAndSaveProps = {
  onCancel: () => void;
  onSave?: () => void;
  cancelText?: string;
  saveText?: string;
  savingText?: string;
  isSaving?: boolean;
  isDisabled?: boolean;
  inputID?: string;
  placement?: 'flex-end' | 'flex-start' | 'space-between';
  noMb?: boolean;
  noSave?: boolean;
};

const sx = {
  width: '166px',
};

const CancelAndSave: React.FC<CancelAndSaveProps> = (props: CancelAndSaveProps) => {
  const {
    onCancel,
    onSave,
    cancelText,
    saveText,
    savingText,
    isSaving,
    isDisabled,
    inputID,
    placement,
    noMb,
    noSave,
  } = props;
  const { formState } = useFormContext();
  const { errors } = formState;

  const cancel = cancelText || 'Cancel';
  const save = isSaving ? savingText || 'Saving...' : saveText || 'Save';
  const hasErrors = !isEmpty(errors);

  const isError = (error: boolean) => {
    if (isDisabled === false) return false;
    return error;
  };

  useLayoutEffect(() => {
    isError(hasErrors);
  }, [hasErrors, errors]);

  return (
    <Box
      component='div'
      display='flex'
      justifyContent={placement ? placement : 'center'}
      alignItems='center'
      mt={4}
      mb={noMb ? 0 : 6}
    >
      <Button sx={sx} onClick={onCancel} variant='secondarybtn' disabled={isSaving}>
        {cancel}
      </Button>
      {!noSave && (
        <Button
          sx={{ ...sx, ml: 2 }}
          onClick={onSave}
          variant={isDisabled ? 'secondarybtn' : 'primarybtn'}
          disabled={isSaving || isError(hasErrors) || isDisabled}
          type='submit'
          form={inputID}
        >
          {save}
        </Button>
      )}
    </Box>
  );
};

export default CancelAndSave;
