export const TRANSPARENT = 'transparent';
export const WHITE = {
  100: '#FFFFFF',
  200: '#F8FAFC',
};

export const GREY = {
  100: '#F0F0F3',
  200: '#D2D2D2',
  400: '#908E8F',
  300: '#6B6C76',
  600: '#454344',
  800: '#201E1F',
};

export const BLACK = {
  100: '#1B191A',
};

export const BLUE = {
  299: '#DCEAF7',
  300: '#CADFF3',
  399: '#A7CAEA',
  400: '#96C0E6',
  500: '#7DA0C0',
  600: '#648099',
  800: '#32404D',
  900: '#10B7F9',
  910: '#0582B3',
};

export const GREEN = {
  100: '#E6F7EE',
  300: '#8ED57D',
};

export const RED = {
  400: '#EA5455',
  500: '#FF4D26',
  600: '#CC2500',
};

export const ORANGE = {
  400: '#FF8A00',
};

export const LILAC = {
  400: '#D5D1FB',
};

export const PURPLE = {
  400: '#A35DA5',
};

export const BE_COLORS = {
  lightGrey: '#D2D2D2',
  darkGrey: '#454344',
  lightGreen: '#D4F2BA',
  darkGreen: '#4E891A',
  lightYellow: '#FFEDAD',
  darkYellow: '#A38000',
};
