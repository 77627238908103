import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Container } from '@mui/material';
import HeaderTabs from 'components/HeaderTabs';
import TrainingHeader from 'components/TrainingHeader';
import UpdateAvatarPhoto from 'components/UpdateAvatarPhoto';
import { TrainingRoutes } from 'enums/routes';
import { useNavigationMenu } from 'hooks/useNavigationMenu';
import useSessionUser from 'hooks/useSessionUser';
import React from 'react';
import { Outlet, useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useCenterInformation } from 'services/center';
import { useStaffMemberById } from 'services/global-academics/staff-members';
import { light } from 'theme/palette';
import Header from 'UI/organisms/global/header';
import Sidebar from 'UI/organisms/global/sidebar';
import GreyHeaderInfo from 'UI/organisms/grey-header-info';

export const tabs = [
  {
    name: 'my-training',
    label: 'My Training',
  },
  {
    name: 'my-staff-training',
    label: 'My Staff Training',
  },
];

const CentersManagementDashboardLayout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { centerId: centerIdFromParams, agencyId = '1', staffId } = useParams();

  const { navigationMenu: parentNodes, permissionsMenu: permissions } = useNavigationMenu();

  const { id: userId } = useSessionUser();
  const { data: userInfo } = useStaffMemberById({
    routeParams: { id: userId },
    config: { enabled: !!userId },
  });

  const centerId = centerIdFromParams || userInfo?.centerId;

  const { data, refetch: refetchCenter } = useCenterInformation({
    routeParams: {
      id: agencyId,
      centerId: centerId,
    },
    config: { enabled: !!centerId },
  });

  const center = data || null;
  const { agencyName = '' } = center ? center.agency : {};
  const centerName = center ? center.name : '';
  const { profilePhotoURL } = center ?? {};
  const shouldShowHeader = location.pathname.includes('center-reporting');
  const shouldShowTrainingTabs = location.pathname.includes(TrainingRoutes.trainingDashboard);
  const { isSupervisor } = userInfo || {};
  const tabsGuard = isSupervisor && !staffId ? tabs : tabs.slice(0, 1);
  const tabName = location.pathname.includes(TrainingRoutes.myTraining)
    ? TrainingRoutes.myTraining
    : TrainingRoutes.myStaffTraining;

  return (
    <Box component='div' bgcolor={light[100]}>
      <Header fixed={false} />
      <Container component='div' maxWidth='xl' sx={{ p: { xs: 1.3 } }}>
        <Box flexDirection={{ xs: 'column', md: 'row' }} component='div' display='flex'>
          <Box
            component='div'
            display='flex'
            flexDirection='column'
            alignItems={{
              xs: 'flex-start',
              md: 'normal',
            }}
            width={{
              xs: '100%',
              md: '270px',
            }}
          >
            <Sidebar
              parentNodes={parentNodes || []}
              moduleName={`agency/${agencyId}/center`}
              selectedParentNode={7}
              idParam={centerId}
              controller='Center'
              permissions={permissions}
            />
          </Box>
          <Box
            component='div'
            width={{
              xs: '100%',
              md: 'calc(100% - 270px)',
            }}
          >
            <Box component='div' pt={2}>
              {!shouldShowHeader && !shouldShowTrainingTabs && center && (
                <GreyHeaderInfo
                  address={center.address}
                  name={centerName || ''}
                  optionalName={agencyName}
                  isOnlyDetail
                  photo={profilePhotoURL as string}
                  refetchCenter={refetchCenter}
                  avatarComponent={
                    <UpdateAvatarPhoto
                      dynamicData={center}
                      centerId={centerId}
                      agencyId={agencyId}
                    />
                  }
                  optionalActionButton={
                    <EditRoundedIcon
                      onClick={() => navigate(`/agency/${agencyId}/center/${centerId}/detail`)}
                      sx={{ cursor: 'pointer' }}
                      fontSize='small'
                      color='primary'
                    />
                  }
                />
              )}
            </Box>
            {shouldShowTrainingTabs && (
              <>
                <HeaderTabs
                  defaultTabName={tabName}
                  tabs={tabsGuard}
                  isSingle={isSupervisor && !staffId}
                />
                {tabName === TrainingRoutes.myTraining && !staffId && (
                  <Box component='div' ml={1} p={2}>
                    <TrainingHeader />
                  </Box>
                )}
              </>
            )}
            <Box component='div'>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CentersManagementDashboardLayout;
