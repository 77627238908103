import { Auth0Provider } from '@auth0/auth0-react';
// Handle Store
import { ThemeProvider } from '@mui/material';
import '@progress/kendo-theme-default/dist/all.css';
import { ENV_VARS } from 'constants/env';
import { ERROR_MESSAGES } from 'constants/errorMessages';
import moment from 'moment';
import 'moment-timezone';
import ReactDOM from 'react-dom/client';
// React Query
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
// Alerts
import ToastNotifications from 'UI/molecules/global/toast-notification';
import * as Yup from 'yup';
import { z as zod } from 'zod';
import './assets/css/index.css';
import './assets/css/stepper.css';
import './assets/css/theme.css';
import AppRouter from './routes';
import { store } from './store';
import theme from './theme';

moment.tz.setDefault('Etc/UTC');
const customZodErrorMap: zod.ZodErrorMap = (issue, ctx) => {
  if (issue.code === zod.ZodIssueCode.too_small) {
    return { message: `Must contain at least ${issue.minimum} character` };
  }

  if (issue.code === zod.ZodIssueCode.too_big) {
    return { message: `Must contain at most ${issue.maximum} character(s)` };
  }

  if (issue.code === zod.ZodIssueCode.custom) {
    return { message: ERROR_MESSAGES.NOT_EMPTY };
  }

  if (issue.code === zod.ZodIssueCode.invalid_union) {
    return { message: ERROR_MESSAGES.NOT_EMPTY };
  }

  if (issue.code === zod.ZodIssueCode.invalid_type) {
    if (issue.expected === 'number' && issue.received === 'nan') {
      return { message: ERROR_MESSAGES.NOT_EMPTY };
    }

    if (issue.expected === 'string' && ['undefined', 'null'].includes(issue.received)) {
      return { message: ERROR_MESSAGES.NOT_EMPTY };
    }

    if (issue.expected === 'date' && ['undefined', 'null'].includes(issue.received)) {
      return { message: ERROR_MESSAGES.NOT_EMPTY };
    }
  }

  return { message: ctx.defaultError };
};

zod.setErrorMap(customZodErrorMap);
// QueryClient for Provider
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

Yup.setLocale({
  mixed: {
    required: 'Required Field',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Auth0Provider
        domain={ENV_VARS.REACT_AUTH0_DOMAIN}
        clientId={ENV_VARS.REACT_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        cacheLocation='localstorage'
        useRefreshTokens={true}
        audience={ENV_VARS.REACT_AUTH0_AUDIENCE}
      >
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools /> */}
          <AppRouter />
          <ToastNotifications />
        </QueryClientProvider>
      </Auth0Provider>
    </ThemeProvider>
  </Provider>,
);
