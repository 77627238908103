import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Backdrop, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PrivateRoute from 'components/HeaderTabs/PrivateRoute';
import AgencyManagementLayout from 'layouts/agency-management';
/* Layouts */
import CenterManagementDashboardLayout from 'layouts/centers-management-dashboard';
// import TrainingLayout from 'layouts/training';
import { NoAccount424 } from 'modules/424';
import AdminTicketFormContainer from 'modules/global-academics/agencies/help-desk-tickets-admin/form/index';
import TicketFormContainer from 'modules/help-desk/form/index';
import { Playground } from 'modules/playground';
import React, { lazy, Suspense, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useNotificationSignal } from 'services/notificationSockets';
import { light } from 'theme/palette';
import ClientLayout from '../layouts/client';
import MainLayout from '../layouts/main';
import { AppDispatch } from '../store';
import { getAccessToken } from '../store/slices/tokens';
import ClientSkeleton from '../UI/organisms/client/global/client-skeleton';
import SkeletonElement from '../UI/organisms/global/skeleton';
import {
  Assessments,
  Client,
  GlobalAcademics,
  IncidentReporting,
  IntakeEnrollment,
  ListsOFServices,
  Medical,
  MentalHealth,
  QualityManagement,
  ServiceForm,
  Training,
  Transition,
} from './imports';
import PrivateAdminRoute from './routeGuards/adminGuards';

/* Pages */
const Dashboard = lazy(() => import('modules/dashboard'));
const TasksView = lazy(() => import('../modules/tasks'));
const NotFound = lazy(() => import('../modules/404'));
const ClientRecord = lazy(() => import('../modules/client/index'));
const ClientSearch = lazy(() => import('../modules/client/search/index'));
const Onbording = lazy(() => import('modules/onbording'));
const ClientHouseholdDetail = lazy(() => import('../modules/client/demographics/household-detail'));
const ClientSchoolInformation = lazy(
  () => import('../modules/client/demographics/school-information'),
);
const ClientCaseStaffInformation = lazy(
  () => import('../modules/client/demographics/case-staff-information'),
);
const ClientAlerts = lazy(() => import('../modules/client/alerts'));
const ClientTreatmentPlanning = lazy(() => import('../modules/client/treatment-planning'));
const TreatmentDetails = lazy(() => import('modules/client/treatment-planning/treatment-details'));
const TreatmentGoalDetails = lazy(() => import('modules/client/treatment-planning/goals/details'));

const ClientAcademics = lazy(() => import('../modules/client/academics/general-fields'));
const ClientAcademicsAttendance = lazy(() => import('../modules/client/academics/attendance'));
const ClientAcademicsTesting = lazy(() => import('../modules/client/academics/testing'));
// const ClientAcademicsGrades = lazy(() => import('../modules/client/academics/grade'));
const ClientAcademicsSchedule = lazy(() => import('../modules/client/academics/class-schedule'));
const Referral = lazy(() => import('modules/client/referral'));
const HelpDeskView = lazy(() => import('../modules/help-desk'));
const HelpDeskAdminView = lazy(
  () => import('../modules/global-academics/agencies/help-desk-tickets-admin/index'),
);

const AppRouter: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { createConnection } = useNotificationSignal();

  const { isLoading } = useQuery('get-token', async () => {
    const accessToken = await getAccessTokenSilently();
    dispatch(getAccessToken(accessToken));
  });

  if (isLoading && !isAuthenticated)
    return (
      <Backdrop open={isLoading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Box
          p={2}
          bgcolor={light[100]}
          borderRadius='6px'
          component='div'
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          <CircularProgress color='info' />
          <Typography mt={1} color='darker'>
            Loading Client Information...
          </Typography>
        </Box>
      </Backdrop>
    );

  const isDevelop = process.env.NODE_ENV === 'development';

  const hasNoAccount = localStorage.getItem('no-account');

  const accesToken = localStorage.getItem('access-token');

  useEffect(() => {
    if (accesToken) {
      createConnection(accesToken);
    }
  }, [accesToken]);

  if (hasNoAccount) return <NoAccount424 />;

  if (localStorage.getItem('access-token'))
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<MainLayout />}>
            {isDevelop && <Route path='/playground' element={<Playground />} />}

            <Route
              path='/'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path='/client/search'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <ClientSearch />
                </Suspense>
              }
            />
            <Route
              path='/client/create'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Onbording />
                </Suspense>
              }
            />
            <Route
              path='/tasks'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <TasksView />
                </Suspense>
              }
            />
            <Route
              path='*'
              element={
                <Suspense
                  fallback={
                    <Backdrop
                      open={isLoading}
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    >
                      <Box
                        p={2}
                        bgcolor={light[100]}
                        border='1px solid black'
                        borderRadius='6px'
                        component='div'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        flexDirection='column'
                      >
                        <CircularProgress color='info' />
                        <Typography mt={1} color='darker'>
                          Loading Client Information...
                        </Typography>
                      </Box>
                    </Backdrop>
                  }
                >
                  <NotFound />
                </Suspense>
              }
            />
            {/* <Route
              path='/agencies'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.Agencies />
                </Suspense>
              }
            /> */}
            <Route
              path='/quality-management'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <QualityManagement />
                </Suspense>
              }
            />
            <Route
              path='/agencies'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.AgencyDashboard />
                </Suspense>
              }
            />
            <Route
              path='/agency/create'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.CreateAgency />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/detail'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.DetailAgency />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/upload-avatar'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.UploadPhotoAgency />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/management'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.AgencyDashboard />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/job-categorization'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.JobCategorization />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/job-categorization/form'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.JobCategorizationForm />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/job-categorization/form/:id/:workitem'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.JobCategorizationForm />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/roles-and-permissions'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.RolesAndPermissions />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/roles-and-permissions/form'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.RolesAndPermissionsForm />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/roles-and-permissions/:role'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.RolesAndPermissionsSiteManager />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/job-title&workitem=:categoryId'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.JobCategory />
                </Suspense>
              }
            />

            {/* REPORTS */}
            <Route
              path='/agency/:agencyId/reporting'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.Reports />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/reporting/service-gap-report'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.GAPReports />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/reporting/attendance-review'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.AttendanceReview />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/reporting/service-reports'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.ServiceReports />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/reporting/served-reports'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.ClientServedReport />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/centers'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.Centers />
                </Suspense>
              }
            />
            <Route
              path='agency/:agencyId/centers/create'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.CreateCenter />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/detail'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.CenterDetail />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/incidents-reporting'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.AgencyIncidentReports />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/incidents-reporting/:incidentId/:section'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.IncidentReportsForm />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/incidents-reporting/:incidentId/:section/:clientId/form/:involvedId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.SectionFormGuardRoute />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/intra-agency-transfer'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.IntraAgencyTransfer />
                </Suspense>
              }
            />
            <Route
              path='/help-desk'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <HelpDeskView />
                </Suspense>
              }
            />
            <Route
              path='/help-desk/create'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <TicketFormContainer />
                </Suspense>
              }
            />
            <Route
              path='/help-desk/:ticketId'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <TicketFormContainer />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/help-desk'
              element={
                <PrivateAdminRoute>
                  <Suspense fallback={<SkeletonElement />}>
                    <HelpDeskAdminView />
                  </Suspense>
                </PrivateAdminRoute>
              }
            />

            <Route
              path='/agency/:agencyId/help-desk/:ticketId'
              element={
                <PrivateAdminRoute>
                  <Suspense fallback={<SkeletonElement />}>
                    <AdminTicketFormContainer />
                  </Suspense>
                </PrivateAdminRoute>
              }
            />
          </Route>

          <Route element={<ClientLayout />}>
            <Route
              path='/client/:id/'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ClientRecord />
                </Suspense>
              }
            />

            <Route
              path='/client/:id/assessments'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Assessments.Assessment />
                </Suspense>
              }
            />

            <Route
              path='/client/:id/assessments/:assestmentType'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Assessments.GlobalAssesmentWrapper />
                </Suspense>
              }
            />

            <Route
              path='/client/:id/assessments/:assestmentType/:formId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Assessments.GlobalAssesmentWrapper />
                </Suspense>
              }
            />

            <Route
              path='/client/:id/eligibility-information'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <MentalHealth.EligibilityInformation />
                </Suspense>
              }
            />

            <Route
              path='/client/:id/household-details/'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ClientHouseholdDetail />
                </Suspense>
              }
            />
            <Route
              path='client/:id/school-information'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ClientSchoolInformation />
                </Suspense>
              }
            />
            <Route
              path='client/:id/case-staff-information'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ClientCaseStaffInformation />
                </Suspense>
              }
            />
            <Route
              path='client/:id/academics'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ClientAcademics />
                </Suspense>
              }
            />
            <Route
              path='client/:id/testing'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ClientAcademicsTesting />
                </Suspense>
              }
            />
            <Route
              path='client/:id/attendance'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ClientAcademicsAttendance />
                </Suspense>
              }
            />
            {/* <Route
              path='client/:id/grades'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ClientAcademicsGrades />
                </Suspense>
              }
            /> */}

            <Route
              path='client/:id/schedule'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ClientAcademicsSchedule />
                </Suspense>
              }
            />

            <Route
              path='client/:id/treatment'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ClientTreatmentPlanning />
                </Suspense>
              }
            />

            <Route
              path='client/:id/treatment/:treatmentId/:section'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <TreatmentDetails />
                </Suspense>
              }
            />

            <Route
              path='client/:id/treatment/:treatmentId/:section/:goalId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <TreatmentGoalDetails />
                </Suspense>
              }
            />
            <Route
              path='client/:id/treatment/:treatmentId/:section/:goalId/:action'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <TreatmentGoalDetails />
                </Suspense>
              }
            />

            <Route
              path='client/:id/growth-and-change'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Client.GrowthAndChange />
                </Suspense>
              }
            />

            <Route
              path='client/:id/growth-and-change/form'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Client.GrowthAndChange />
                </Suspense>
              }
            />

            <Route
              path='client/:id/growth-and-change/form:id'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Client.GrowthAndChange />
                </Suspense>
              }
            />

            <Route
              path='client/:id/alert'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ClientAlerts />
                </Suspense>
              }
            />

            {/* Medical */}
            <Route
              path='client/:id/health-record'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.HealthRecord />
                </Suspense>
              }
            />

            <Route
              path='client/:id/health-record/medical-provider/form'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.MedicalProvider />
                </Suspense>
              }
            />

            <Route
              path='client/:id/health-record/medical-provider/form/:medicalProviderId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.MedicalProvider />
                </Suspense>
              }
            />

            <Route
              path='client/:id/service-list'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ListsOFServices />
                </Suspense>
              }
            />

            <Route
              path='client/:id/service-list/form'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ServiceForm />
                </Suspense>
              }
            />

            <Route
              path='client/:id/service-list/form/:formId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <ServiceForm />
                </Suspense>
              }
            />

            <Route
              path='client/:id/diagnosis-immunizations'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.DiagnosisImmunizations />
                </Suspense>
              }
            />
            <Route
              path='client/:id/diagnosis-immunizations/:section'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.DiagnosisImmunizations />
                </Suspense>
              }
            />
            <Route
              path='client/:id/diagnosis-immunizations/:section/:formId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.DiagnosisImmunizations />
                </Suspense>
              }
            />
            <Route
              path='client/:id/current-substance-use'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.CurrentSubstanceUse />
                </Suspense>
              }
            />

            <Route
              path='client/:id/current-substance-use/drug-use-information/form'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.CurrentSubstanceUse />
                </Suspense>
              }
            />

            <Route
              path='client/:id/current-substance-use/drug-use-information/form/:drugId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.CurrentSubstanceUse />
                </Suspense>
              }
            />

            <Route
              path='client/:id/medical-assessments'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.MedicalAssessments />
                </Suspense>
              }
            />

            <Route
              path='client/:id/medical-assessments/:section'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.MedicalAssessments />
                </Suspense>
              }
            />

            <Route
              path='client/:id/medical-assessments/:section/form'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.MedicalAssessmentsForm />
                </Suspense>
              }
            />

            <Route
              path='client/:id/medical-assessments/:section/form/:formId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.MedicalAssessmentsForm />
                </Suspense>
              }
            />

            {/* <Route
              path='client/:id/medication-information'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.MedicationInformation />
                </Suspense>
              }
            />

            <Route
              path='client/:id/medication-information/:section'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.MedicationInformation />
                </Suspense>
              }
            />

            <Route
              path='client/:id/medication-information/:section/form'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.MedicationInformationForms />
                </Suspense>
              }
            />

            <Route
              path='client/:id/medication-information/:section/form/:formId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Medical.MedicationInformationForms />
                </Suspense>
              }
            /> */}

            {/* Intake */}
            <Route
              path='client/:id/intake-enrollment'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <IntakeEnrollment.Intake />
                </Suspense>
              }
            />
            <Route
              path='client/:id/intra-agency'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <IntakeEnrollment.IntraAgency />
                </Suspense>
              }
            />
            <Route
              path='client/:id/consent/:consentId/:consentRef'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <IntakeEnrollment.Consents />
                </Suspense>
              }
            />
            <Route
              path='client/:id/consent-form/:consentType/:consentRef'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <IntakeEnrollment.Consents />
                </Suspense>
              }
            />
            {/* Transition Discharge */}
            <Route
              path='client/:id/transition-planning'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Transition.Planning />
                </Suspense>
              }
            />
            <Route
              path='client/:id/exit-plan'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Transition.ExitPlan />
                </Suspense>
              }
            />
            {/* Incident reporting */}
            <Route
              path='client/:id/incident-reporting'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <IncidentReporting.IncidentReportingTable />
                </Suspense>
              }
            />
            <Route
              path='client/:id/incident-reporting/:incidentId/:section'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.IncidentReportsForm />
                </Suspense>
              }
            />
            <Route
              path='client/:id/incident-reporting/:incidentId/:section/:clientId/form/:involvedId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.SectionFormGuardRoute />
                </Suspense>
              }
            />
            <Route
              path='client/:id/referral'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <Referral />
                </Suspense>
              }
            />
          </Route>

          <Route element={<CenterManagementDashboardLayout />}>
            <Route
              path='/agency/:agencyId/center/:centerId/center-reporting'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.CenterReporting />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/waitlist'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.CenterWaitlist />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/center-reporting/service-reports'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.CenterReportingServiceReports />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/center-reporting/served-reports'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.CenterClientServedReport />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/center-reporting/service-gap-reports'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.CenterServiceGapReport />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/center-reporting/attendance-review'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.CenterAttendanceReview />
                </Suspense>
              }
            />
          </Route>

          <Route element={<CenterManagementDashboardLayout />}>
            <Route
              path='/center-management'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.CenterInformation />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/center-information'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <GlobalAcademics.CenterInformation />
                </Suspense>
              }
            />

            {/* Academic Set Up Links */}
            <Route
              path='/agency/:agencyId/center/:centerId/academic-setup'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.AcademicSetup />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/academic-setup/academic-years'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.AcademicSetup />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/academic-setup/blocks'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.AcademicSetup />
                </Suspense>
              }
            ></Route>
            <Route
              path='/agency/:agencyId/center/:centerId/academic-setup/periods'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.AcademicSetup />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/academic-setup/calendar'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.AcademicSetup />
                </Suspense>
              }
            />
            {/* // Teachers Routes */}
            <Route
              path='/agency/:agencyId/center/:centerId/teachers/save'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.SaveTeacher />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/teachers/:teacherId/save'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.SaveTeacher />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/teachers'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.ListTeachers />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/teachers/:teacherId/upload-picture'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.UploadTeacherPicture />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/academic'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.Courses />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/academic/:section'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.Courses />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/academic/course-schedule/create'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.CourseScheduleSetup />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/academic/global-roster/:rosterId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.GlobalRoster />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/courses/schedule/:courseScheduleId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.CourseScheduleSetup />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/academic/courses/create'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.SaveCourse />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/courses/:courseId/edit'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.SaveCourse />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/incidents-reporting'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.IncidentReports />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/incidents-reporting/create/:section'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.IncidentReportsForm />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/incidents-reporting/:incidentId/:section'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.IncidentReportsForm />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/incidents-reporting/:incidentId/:section/:clientId/form/:involvedId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.SectionFormGuardRoute />
                </Suspense>
              }
            />

            {/* <Route
              path='/agency/:agencyId/center/:centerId/incidents-reporting/follow-info/:incidentNumber/:incidentId/:typeId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <IncidentReporting.FollowUps />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/incidents-reporting/follow-info/:incidentNumber/:incidentId/:typeId/form'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <IncidentReporting.FollowUpsForm />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/incidents-reporting/follow-info/:incidentNumber/:incidentId/:typeId/form/:followUpId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <IncidentReporting.FollowUpsForm />
                </Suspense>
              }
            /> */}

            <Route
              path='/agency/:agencyId/center/:centerId/incidents-reporting/form/:incidentId'
              element={
                <Suspense fallback={<ClientSkeleton />}>
                  <GlobalAcademics.IncidentReportsForm />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/center/:centerId/training-dashboard/my-training/'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Training.TrainingDashboard />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/training-dashboard/my-training/:section'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Training.TrainingDashboard />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/training-dashboard/my-training/:section/staff/:staffId'
              element={
                <PrivateRoute>
                  <Suspense fallback={<SkeletonElement />}>
                    <Training.TrainingDashboard />
                  </Suspense>
                </PrivateRoute>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/training-dashboard/my-training/:section/form'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Training.TrainingDashboard />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/training-dashboard/my-training/:section/form/:formId'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Training.TrainingDashboard />
                </Suspense>
              }
            />

            <Route
              path='/agency/:agencyId/center/:centerId/training-dashboard/my-staff-training/'
              element={
                <PrivateRoute>
                  <Suspense fallback={<SkeletonElement />}>
                    <Training.TrainingDashboard />
                  </Suspense>
                </PrivateRoute>
              }
            />
          </Route>

          <Route element={<AgencyManagementLayout />}>
            <Route
              path='/agency/:agencyId/training-setup/'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Training.TrainingSetup />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/training-setup/courses-database'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Training.CoursesDatabase />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/training-setup/courses-database/form'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Training.CoursesDatabaseForm />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/training-setup/courses-database/form/:courseId'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Training.CoursesDatabaseForm />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/training-setup/training-plans'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Training.TrainingPlans />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/training-setup/training-plans/form'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Training.TrainingPlansForm />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/training-setup/training-plans/form/:courseId'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Training.TrainingPlansForm />
                </Suspense>
              }
            />
            <Route
              path='/agency/:agencyId/training-setup/training-plans/form/:courseId/assign'
              element={
                <Suspense fallback={<SkeletonElement />}>
                  <Training.TrainingPlansFormAssign />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    );

  return <div>Error</div>;
};

export default withAuthenticationRequired(AppRouter);
