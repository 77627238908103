import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { BLACK } from 'theme/palette/new';
import mod from './BaseText.module.scss';

export enum SpanSizes {
  small = '28px',
  regular = '32px',
  big = '44px',
}

export enum PSizes {
  small = '12px',
}

export enum TextElement {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  p = 'p',
  span = 'span',
}

export enum FontWeight {
  regular = 'regular',
  medium = 'medium',
  bold = 'bold',
}

const classes = {
  baseText: mod['base-text'],
  ellipsis: mod.ellipsis,
  underlined: mod.underlined,
};

type BaseTextProps = {
  /* Text content */
  children: string | string[] | React.ReactNode | ReactNode[];
  /* HTML element used */
  element: TextElement;
  /* Font Weight of the element */
  fontWeight: FontWeight;
  /* Optional color of the text content */
  color?: string;
  /* Optional maximum width for the text */
  maxWidth?: string;
  /* Optinal should component use ellipsis */
  ellipsis?: boolean;
  /* Optinal custom font size */
  fontSize?: string;
  /* Optinal custom align */
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  /* Optinal sets text underline decorator */
  underlined?: boolean;
};

export type TextProps = {
  /* Text content */
  children: string | string[] | React.ReactNode | ReactNode[];
  /* Optional color of the text content */
  color?: string;
  /* Optinal sets text underline decorator */
  underlined?: boolean;
  /* Optinal custom align */
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
};

export type WithFontWeight = {
  /* Optional Font Weight of the element */
  fontWeight?: FontWeight;
};

export type SpanProps = TextProps &
  WithFontWeight & {
    /* Optional Size of the element */
    size?: SpanSizes & PSizes;
  };

export const BaseText: FC<BaseTextProps> = (props: BaseTextProps) => {
  const {
    children,
    element,
    fontWeight,
    color = BLACK[100],
    maxWidth,
    ellipsis,
    fontSize,
    textAlign,
    underlined,
  } = props;

  const className = classNames(classes.baseText, [mod[element], mod[fontWeight]], {
    [classes.ellipsis]: ellipsis,
    [classes.underlined]: underlined,
  });

  const Element = element;
  return (
    <Element className={className} style={{ fontSize, maxWidth, color, textAlign }}>
      {children}
    </Element>
  );
};
