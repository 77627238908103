import { lazy } from 'react';
/* Medical */
const HealthRecord = lazy(() => import('modules/client/medical/health-record'));
const MedicalProvider = lazy(
  () => import('modules/client/medical/health-record/medical-provider/medicalProviderSave'),
);
const DiagnosisImmunizations = lazy(() => import('modules/client/medical/diagnosis-immunizations'));
const CurrentSubstanceUse = lazy(() => import('modules/client/medical/current-substance-use'));
const MedicalAssessments = lazy(() => import('modules/client/medical/medical-assessment'));
const MedicalAssessmentsForm = lazy(
  () => import('modules/client/medical/medical-assessment/forms'),
);
const MedicationInformation = lazy(() => import('modules/client/medical/medication-information'));
const MedicationInformationForms = lazy(
  () => import('modules/client/medical/medication-information/forms'),
);
/* List of Services */
export const ListsOFServices = lazy(() => import('modules/client/list-of-services'));
export const ServiceForm = lazy(() => import('modules/client/list-of-services/form/container'));
/* Intake */
export const Intake = lazy(() => import('modules/client/intake-enrollment/index'));
export const IntraAgency = lazy(() => import('modules/client/intake-enrollment/intra-agency'));
export const Consents = lazy(
  () => import('modules/client/intake-enrollment/consents/enrollmentConsentForms/container'),
);
/* Transition Discharge */
export const TransitionPlanning = lazy(
  () => import('modules/client/transition-discharge/transition-planning/container'),
);
export const ExitPlan = lazy(() => import('modules/client/transition-discharge/exit-plan'));
/* Incident reporting */
export const IncidentReportingTable = lazy(() => import('modules/client/incident-reporting/table'));
/* Global academics */
export const Agencies = lazy(() => import('modules/global-academics/agencies'));
export const AgencyDashboard = lazy(() => import('modules/global-academics/agencies/dashboard'));
export const JobCategorization = lazy(
  () => import('modules/global-academics/agencies/staff-job-categorization'),
);
export const JobCategorizationForm = lazy(
  () => import('modules/global-academics/agencies/staff-job-categorization/form/container'),
);
export const RolesAndPermissions = lazy(
  () => import('modules/global-academics/agencies/roles-and-permission'),
);
export const RolesAndPermissionsForm = lazy(
  () => import('modules/global-academics/agencies/roles-and-permission/RoleForm'),
);
export const RolesAndPermissionsSiteManager = lazy(
  () => import('modules/global-academics/agencies/roles-and-permission/siteManager'),
);
export const CreateAgency = lazy(() => import('modules/global-academics/agencies/create'));
export const DetailAgency = lazy(() => import('modules/global-academics/agencies/detail'));
export const UploadPhotoAgency = lazy(
  () => import('modules/global-academics/agencies/form/uplodad-photo'),
);
/* REPORTS */
export const Reports = lazy(
  () => import('modules/global-academics/agencies/agency-management/reporting/index'),
);
export const GAPReports = lazy(
  () => import('modules/global-academics/agencies/agency-management/reporting/gap-report/index'),
);
export const ServiceReports = lazy(
  () => import('modules/global-academics/agencies/agency-management/reporting/service-reports'),
);
export const AttendanceReview = lazy(
  () =>
    import(
      'modules/global-academics/agencies/agency-management/reporting/attendance-review/container'
    ),
);

export const ClientServedReport = lazy(
  () =>
    import('modules/global-academics/agencies/agency-management/reporting/client-served-report'),
);
/* CENTERS */
export const Centers = lazy(() => import('modules/global-academics/centers'));
export const CreateCenter = lazy(() => import('modules/global-academics/centers/create'));
export const AcademicSetup = lazy(() => import('modules/global-academics/centers/academic-setup'));
export const CourseScheduleSetup = lazy(
  () => import('modules/global-academics/centers/courses/course-schedule/form'),
);
export const GlobalRoster = lazy(
  () => import('modules/global-academics/centers/courses/gobal-roster/global-roster'),
);
export const SaveTeacher = lazy(
  () => import('modules/global-academics/centers/staff/form/container'),
);
export const ListTeachers = lazy(() => import('modules/global-academics/centers/staff/index'));
export const UploadTeacherPicture = lazy(
  () => import('modules/global-academics/centers/staff/form/avatarContainer'),
);

export const CenterDetail = lazy(() => import('modules/global-academics/centers/details'));
export const CenterInformation = lazy(
  () => import('modules/global-academics/centers/center-information'),
);
export const Courses = lazy(() => import('modules/global-academics/centers/courses'));
export const SaveCourse = lazy(
  () => import('modules/global-academics/centers/courses/courses/save'),
);
export const JobCategory = lazy(
  () => import('modules/global-academics/agencies/staff-job-categorization/job-titles/index'),
);
export const IncidentReports = lazy(
  () => import('modules/global-academics/centers/incidents-reporting'),
);
export const IncidentReportsForm = lazy(
  () => import('modules/global-academics/centers/incidents-reporting/tabs/index'),
);
export const SectionFormGuardRoute = lazy(
  () =>
    import('modules/global-academics/centers/incidents-reporting/tabs/form/sectionFormRouteGuard'),
);
export const CenterReporting = lazy(() => import('modules/global-academics/centers/reporting'));
export const CenterReportingServiceReports = lazy(
  () => import('modules/global-academics/centers/reporting/service-reports'),
);

export const CenterWaitlist = lazy(() => import('modules/global-academics/centers/waitlist'));

export const CenterClientServedReport = lazy(
  () => import('modules/global-academics/centers/reporting/served-reports'),
);
export const CenterServiceGapReport = lazy(
  () => import('modules/global-academics/centers/reporting/service-gap-report'),
);
export const CenterAttendanceReview = lazy(
  () => import('modules/global-academics/centers/reporting/attendace-review/container'),
);
export const IntraAgencyTransfer = lazy(
  () => import('modules/global-academics/agencies/intra-agency-transfer'),
);
export const AgencyIncidentReports = lazy(
  () => import('modules/global-academics/agencies/incident-reports'),
);

export const EligibilityInformation = lazy(
  () => import('modules/client/mental-health/eligibility-information/container'),
);

export const TrainingDashboard = lazy(() => import('modules/training'));
export const TrainingSetup = lazy(
  () => import('modules/global-academics/agencies/agency-management/agency-training-setup/index'),
);
export const CoursesDatabase = lazy(
  () => import('modules/global-academics/agencies/agency-management/course-database/index'),
);
export const CoursesDatabaseForm = lazy(
  () =>
    import('modules/global-academics/agencies/agency-management/course-database/form/container'),
);
export const TrainingPlans = lazy(
  () => import('modules/global-academics/agencies/agency-management/training-plans'),
);
export const TrainingPlansForm = lazy(
  () => import('modules/global-academics/agencies/agency-management/training-plans/form/container'),
);
export const TrainingPlansFormAssign = lazy(
  () =>
    import('modules/global-academics/agencies/agency-management/training-plans/assign/container'),
);

export const GlobalAssesmentWrapper = lazy(
  () => import('modules/client/assessment/globalAssessmentsFormWrapper'),
);

export const Assessment = lazy(() => import('modules/client/assessment'));
export const AssessmentDJJPrevention = lazy(
  () => import('modules/client/assessment/forms/DJJ-Prevention'),
);
export const AssessmentDJJCommon = lazy(() => import('modules/client/assessment/forms/DJJ-Common'));
export const AssessmentHumanTrafficking = lazy(
  () => import('modules/client/assessment/forms/Human-Trafficking'),
);
export const AssessmentPaceLegal = lazy(() => import('modules/client/assessment/forms/pace-legal'));
export const AssessmentPaceNeeds = lazy(
  () => import('modules/client/assessment/forms/PACE-ongoing-needs'),
);

export const ColumbiaScreening = lazy(
  () => import('modules/client/assessment/forms/columbia-screening'),
);
export const POSIT = lazy(() => import('modules/client/assessment/forms/POSIT/index'));
export const PONA = lazy(() => import('modules/client/assessment/forms/PONA'));
export const SuicideIdeation = lazy(
  () => import('modules/client/assessment/forms/suicide-ideation'),
);
export const STARTest = lazy(() => import('modules/client/assessment/forms/STAR-Test'));
export const PACETherapy = lazy(() => import('modules/client/assessment/forms/PACE-Teraphy'));
export const PACESubstanceUse = lazy(
  () => import('modules/client/assessment/forms/PACE-Subtance-use'),
);
export const PACETransitionSurvey = lazy(
  () => import('modules/client/assessment/forms/PACE-Transiton-survey'),
);
export const SelfEfficacyScale = lazy(
  () => import('modules/client/assessment/forms/Self-Efficacy-Scale'),
);
export const MathCommonAssessment = lazy(
  () => import('modules/client/assessment/forms/Math-Common-Assessment'),
);
export const ELACommonAssessment = lazy(
  () => import('modules/client/assessment/forms/ELA-Common-assessment'),
);
export const GrowthAndChange = lazy(() => import('modules/client/growth-and-change'));
export const GrowthAndChangeForm = lazy(
  () => import('modules/client/growth-and-change/form/container'),
);
export const QualityManagement = lazy(() => import('modules/quality-management'));

export const Client = {
  GrowthAndChange,
  GrowthAndChangeForm,
};

export const Assessments = {
  GlobalAssesmentWrapper,
  Assessment,
  AssessmentDJJPrevention,
  AssessmentDJJCommon,
  AssessmentHumanTrafficking,
  AssessmentPaceLegal,
  AssessmentPaceNeeds,
  ColumbiaScreening,
  POSIT,
  SuicideIdeation,
  STARTest,
  PACETherapy,
  PACESubstanceUse,
  PACETransitionSurvey,
  SelfEfficacyScale,
};

export const MentalHealth = {
  EligibilityInformation,
};

export const Medical = {
  HealthRecord,
  MedicalProvider,
  DiagnosisImmunizations,
  CurrentSubstanceUse,
  MedicalAssessments,
  MedicalAssessmentsForm,
  MedicationInformation,
  MedicationInformationForms,
};

export const Transition = {
  Planning: TransitionPlanning,
  ExitPlan,
};

export const IntakeEnrollment = {
  Intake,
  IntraAgency,
  Consents,
};

export const IncidentReporting = {
  IncidentReportingTable,
};

export const GlobalAcademics = {
  Agencies,
  AgencyDashboard,
  JobCategorization,
  JobCategorizationForm,
  RolesAndPermissions,
  RolesAndPermissionsForm,
  RolesAndPermissionsSiteManager,
  CreateAgency,
  DetailAgency,
  UploadPhotoAgency,
  Centers,
  CreateCenter,
  CenterDetail,
  CenterInformation,
  CourseScheduleSetup,
  GlobalRoster,
  Courses,
  SaveCourse,
  AcademicSetup,
  SaveTeacher,
  ListTeachers,
  UploadTeacherPicture,
  IncidentReports,
  IncidentReportsForm,
  SectionFormGuardRoute,
  JobCategory,
  IntraAgencyTransfer,
  AgencyIncidentReports,
  /* REPORTS */
  Reports,
  GAPReports,
  ServiceReports,
  ClientServedReport,
  AttendanceReview,
  CenterReporting,
  CenterReportingServiceReports,
  CenterWaitlist,
  CenterClientServedReport,
  CenterServiceGapReport,
  CenterAttendanceReview,
};

export const Training = {
  TrainingDashboard,
  CoursesDatabase,
  CoursesDatabaseForm,
  TrainingPlans,
  TrainingPlansForm,
  TrainingPlansFormAssign,
  TrainingSetup,
};
