const filterOptions = () => {
  return {
    type: 'main',
    options: [
      {
        label: 'Items1',
        field: 'item1Id',
        type: 'text',
        options: [
          { label: 'item1', id: 1 },
          { label: 'item2', id: 2 },
        ],
      },
      {
        label: 'Items2',
        field: 'item2Id',
        type: 'text',
        options: [
          { label: 'item1', id: 1 },
          { label: 'item2', id: 2 },
        ],
      },
      {
        label: 'Items3-nested',
        field: 'itemNestedId',
        type: 'textAndSubMenu',
        options: [
          { label: 'item1', id: 1 },
          {
            label: 'items2',
            id: 1,
            field: 'itemNested',
            type: 'nestedText',
            options: [
              { label: 'item1', id: 1 },
              { label: 'item2', id: 2 },
            ],
          },
          { label: 'item3', id: 2 },
        ],
      },
      {
        label: 'Month',
        field: 'dateSubmitted',
        type: 'calendar',
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(2025, 11, 31),
      },
    ],
  };
};

import AcUnitIcon from '@mui/icons-material/AcUnit';
import { Box, Button } from '@mui/material';
import { FilterBy } from 'components/fields/FilterBy';
import { FormProvider, useForm } from 'react-hook-form';
export const Playground = () => {
  const methods = useForm();
  const filter = methods.watch('filter');
  console.log(filter);
  return (
    <Box p={4}>
      <FormProvider {...methods}>
        <FilterBy fieldName='filter' options={filterOptions()} />
        <Box width='33%'>
          <Button variant='primarybtn' startIcon={<AcUnitIcon />}>
            TEST
          </Button>
          <Button variant='primarybtn' disabled startIcon={<AcUnitIcon />}>
            TEST
          </Button>
          <Button variant='secondarybtn' startIcon={<AcUnitIcon />}>
            TEST
          </Button>
          <Button variant='secondarybtn' disabled startIcon={<AcUnitIcon />}>
            TEST
          </Button>
          <Button variant='tertiarybtn' startIcon={<AcUnitIcon />}>
            TEST
          </Button>
          <Button variant='tertiarybtn' disabled startIcon={<AcUnitIcon />}>
            TEST
          </Button>
          <Button variant='tertiarybtnSmall' startIcon={<AcUnitIcon />}>
            TEST
          </Button>
          <Button variant='tertiarybtnSmall' disabled startIcon={<AcUnitIcon />}>
            TEST
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
};
