import { Box, Breadcrumbs, Container, Link, Typography } from '@mui/material';
import { useNavigationMenu } from 'hooks/useNavigationMenu';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router';
import { AppDispatch, RootState } from 'store';
import { handleCloseModal } from 'store/slices/menu';
import { dark, light } from 'theme/palette';
import BreadcrumbNavigation from 'UI/molecules/global/breadcrumbs';
import ClientRecordHero from 'UI/organisms/client/global/client-record-hero';
import RecordModal from 'UI/organisms/client/global/client-record-modal';
import Header from 'UI/organisms/global/header';
import Sidebar from 'UI/organisms/global/sidebar';
import menuPayload from './data.json';

const ClientLayout: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { open, modal } = useSelector((state: RootState) => state.menu);

  const { id: clientID } = useParams();

  const { navigationMenu: parentNodes, permissionsMenu: permissions } = useNavigationMenu();

  const closeModal = () => {
    dispatch(handleCloseModal());
  };

  return (
    <Box component='div' bgcolor={light[100]}>
      <Header fixed={true} />
      <RecordModal open={modal} onClose={closeModal} />
      <Container component='div' maxWidth='xl' sx={{ p: { xs: 1.3 } }}>
        <Box
          flexDirection={{ xs: 'column', md: 'row' }}
          component='div'
          display='flex'
          mt={open ? 10 : 18}
        >
          <Box
            component='div'
            display='flex'
            flexDirection='column'
            alignItems={{
              xs: 'flex-start',
              md: 'normal',
            }}
            width={{
              xs: '100%',
              md: '270px',
            }}
          >
            <Sidebar
              idParam={clientID}
              parentNodes={parentNodes || []}
              menuPayload={menuPayload}
              permissions={permissions}
              moduleName='client'
              controller='Client'
            />
          </Box>
          <Box
            component='div'
            width={{
              xs: '100%',
              md: 'calc(100% - 270px)',
            }}
          >
            <Box component='div' pl={{ xs: 0, md: 1 }} pt={2}>
              <Box
                component='div'
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <BreadcrumbNavigation rightElement={false} rightText='show less'>
                  <Breadcrumbs separator={'|'}>
                    <Link underline='hover' color={dark[200]}>
                      <Typography component='p' variant='body2'>
                        Client Management
                      </Typography>
                    </Link>
                    <Link underline='hover' color='primary'>
                      <Typography component='p' variant='body2'>
                        Client Info
                      </Typography>
                    </Link>
                  </Breadcrumbs>
                </BreadcrumbNavigation>
              </Box>
              <ClientRecordHero />
            </Box>
            <Box component='div' pl={{ xs: 0, md: 1 }}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ClientLayout;
