import { API_ROUTES } from 'constants/apiRoutes';
import { dateZodType } from 'constants/zodTypes';
import { getDefaults } from 'utils/get-defaults';
import {
  useFetch,
  usePost,
  usePut,
  UseServiceProps,
  UseServicePropsSingle,
} from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';

export enum ticketFormFields {
  id = 'id',
  supportTypeId = 'supportTypeId',
  programTypeId = 'programTypeId',
  outsideOfCurrentMonthRequest = 'outsideOfCurrentMonthRequest',
  priorityId = 'priorityId',
  dateNeededBy = 'dateNeededBy',
  isCensusRelated = 'isCensusRelated',
  providedServiceTypeId = 'providedServiceTypeId',
  serviceId = 'serviceId',
  attendanceTypeId = 'attendanceTypeId',
  descriptionOfIssueAndChangeNeeded = 'descriptionOfIssueAndChangeNeeded',
  associatedUserInfo = 'associatedUserInfo',
  managerInvolvedInfo = 'managerInvolvedInfo',
  associatedUser = 'associatedUser',
  managerInvolved = 'managerInvolved',
  assignedAssociateDirectorId = 'assignedAssociateDirectorId',
  additionalNotificationId = 'additionalNotificationId',
  statusId = 'statusId',
  helpDeskOwnerId = 'helpDeskOwnerId',
  helpDeskRecordTypeId = 'helpDeskRecordTypeId',
  supportingDocumentationFiles = 'supportingDocumentationFiles',
}

export const GenericDataSchema = z.object({
  id: z.number(),
  attributeName: z.string().nullable(),
  attributeValue: z.string().nullable(),
});

export const GenericPersonalDataSchema = z.object({
  id: z.number(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
});

const SERVICE_NAME = 'Create Help-desk Ticket';

export const TicketFormSchema = z.object({
  [ticketFormFields.id]: z.number(),
  [ticketFormFields.supportTypeId]: z.number().optional().nullable(),
  [ticketFormFields.programTypeId]: z.number().optional().nullable(),
  [ticketFormFields.outsideOfCurrentMonthRequest]: z.boolean(),
  [ticketFormFields.priorityId]: z.number().min(1, { message: 'Required' }),
  [ticketFormFields.dateNeededBy]: dateZodType,
  [ticketFormFields.isCensusRelated]: z.boolean(),
  [ticketFormFields.providedServiceTypeId]: z.number().optional().nullable(),
  [ticketFormFields.serviceId]: z.string().optional(),
  [ticketFormFields.attendanceTypeId]: z.number().optional().nullable(),
  [ticketFormFields.descriptionOfIssueAndChangeNeeded]: z.string().max(5000).optional(),
  [ticketFormFields.associatedUserInfo]: GenericPersonalDataSchema.optional().nullable(),
  [ticketFormFields.managerInvolvedInfo]: GenericPersonalDataSchema.optional().nullable(),
  [ticketFormFields.associatedUser]: z.string().optional(),
  [ticketFormFields.managerInvolved]: z.string().optional(),
  [ticketFormFields.assignedAssociateDirectorId]: z.number().optional().nullable(),
  [ticketFormFields.additionalNotificationId]: z.number().optional().nullable(),
  [ticketFormFields.statusId]: z.number().optional(),
  [ticketFormFields.helpDeskOwnerId]: z.number().optional().nullable(),
  [ticketFormFields.helpDeskRecordTypeId]: z.number().optional().nullable(),
  [ticketFormFields.supportingDocumentationFiles]: z.array(z.any()).optional(),
});

export type TicketFormType = z.infer<typeof TicketFormSchema>;

export const TicketFormBooleanDefaults = getDefaults<typeof TicketFormSchema>(TicketFormSchema);

export const useGetTicket = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<TicketFormType>) =>
  useFetch<TicketFormType>(pathToUrl(API_ROUTES.TICKET_DETAIL, routeParams), params, config);

export const useAddTicket = ({ routeParams }: UseServiceProps<TicketFormType>) =>
  usePost<TicketFormType[], TicketFormType>(
    pathToUrl(API_ROUTES.ADDTICKET, routeParams),
    undefined,
    SERVICE_NAME,
  );

export const useUpdateTicket = ({ routeParams }: UseServiceProps<TicketFormType>) =>
  usePut<TicketFormType[], TicketFormType>(
    pathToUrl(API_ROUTES.ADDTICKET, routeParams),
    undefined,
    SERVICE_NAME,
  );
