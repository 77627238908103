import { BLUE, GREY } from 'theme/palette/new';

export const filterStyle = {
  height: '48px',
  padding: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  border: `1px solid ${BLUE[600]}`,
  borderRadius: '10px',
  '& > svg': {
    transition: 'transform .3s ease-out',
  },
};

export const filterOptionsStyle = {
  '& .MuiPaper-elevation': {
    boxShadow: '0px 2px 8px 0px #45434433',
    // border: '1px solid red',
    borderRadius: '10px',
    marginTop: 1,
  },
  '& ul': {
    padding: 0,
  },
  '& .back': {
    borderBottom: `1px solid ${GREY[200]}`,
  },
  '& li': {
    height: '48px',
  },
};
