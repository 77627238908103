export enum API_ROUTES {
  // AZURE FILES
  AZURE_UPLOAD_FILE_INCIDENTS = '/client/incident-reports/upload-url',
  AZURE_DELETE_FILE_INCIDENTS = '/client/incident-reports/delete-files',
  AZURE_UPLOAD_FILE_HELP_DESK = '/staff-members/help-desk-ticket/upload-url',
  AZURE_DELETE_FILE_HELP_DESK = '/staff-members/help-desk-ticket/delete-files',
  // NAVIGATION
  USER_PERMISSIONS = '/Permissions/user',
  // NAVIGATION
  NAVIGATION = '/navigation',
  // AGENCY:
  DASHBOARD = '/Dashboard',
  // AGENCY:
  AGENCY = '/agency',
  // AGENCY:
  CENTERS = '/center/',
  CENTERS_BY_AGENCY = '/agency/:id/center',
  // JOB CATEGORY
  JOB_CATEGORIES = '/job-categories',
  JOB_CATEGORY_BY_ID = '/job-categories/:id',
  JOB_CATEGORY_BY_CLIENT = '/agency/:id/job-categories',
  // CENTER:
  CENTER_INFO = '/agency/:id/center/:centerId',
  CENTER_PHOTO = '/agency/:id/center/:centerId/files',
  // STUDENTS
  STUDENTS_BY_CENTER = 'centers/:id/client/',
  // COURSES SCHEDULES
  COURSES_SCHEDULES_BY_CENTER = 'centers/:id/course-schedules',
  COURSE_SCHEDULE_BY_ID = 'course-schedules/:id',
  // AGENCY COURSE DATABASE
  COURSE_DATABASE_BY_AGENCY = '/agencies/:id/agency-courses',
  AGENCY_COURSE_DATABASE = '/agency-courses',
  AGENCY_COURSE_DATABASE_BY_ID = '/agency-courses/:id',
  // STAFF MEMBERS
  STAFF_MEMBERS = '/staff-members',
  STAFF_MEMBERS_BY_ID = '/staff-members/:id',
  STAFF_MEMBER_HEADER_BY_ID = '/staff-members/:id/header',
  STAFF_MEMBERS_BY_CLIENT = '/centers/:id/staff-members',
  STAFF_MEMBERS_BY_AGENCY = '/agency/:id/staff-members',
  STAFF_MEMBERS_AVATAR = '/staff-members/:id/avatar',
  STAFF_SUPERVISORS_BY_CENTER = '/centers/:id/supervisors',
  STAFF_MEMBERS_BY_SUPERVISOR = '/supervisors/:id/staff-members',
  STAFF_CATEGORIES = '/centers/:id/staff-members/category/:categoryId',
  STAFF_MEMBERS_SEARCH_ALL = '/SearchStaffMember',
  STAFF_MEMEBRS_BY_ROLE = '/staff-members/role',
  // DEMOGRAPHICS:
  // SCHOOL INFORMATION
  SCHOOL_INFORMATION_BY_CLIENT = '/client/:id/school-info',
  SCHOOL_INFORMATION = '/client/school-information',
  // ACADEMICS:
  // SCHOOL ENROLLMENT HISTORY
  SCHOOL_ENROLLMENT_HISTORY_BY_CLIENT = '/client/:id/school-enrollments/all',
  SCHOOL_ENROLLMENT_HISTORY = '/school-enrollments',
  // CASE STAFF INFORMATION
  CASE_STAFF_INFORMATION_BY_CLIENT = '/client/:id/case-staff-information',
  CASE_STAFF_INFORMATION = '/client/case-staff-information',
  // ACADEMIC YEARS
  ACADEMIC_YEARS_BY_CENTER = '/center/:id/academic-years',
  // CLIENTS
  CLIENTS_SEARCH = '/searchclient',
  CLIENTS_ALL_SEARCH = '/SearchAllClient',
  CLIENTS_BY_CENTER = '/centers/:id/client',
  CLIENTS_BY_AGENCY = '/agency/:id/client',
  CLIENT_BY_ID = '/client/:id',
  CLIENT = '/client',
  // ALERTS
  CLIENT_ALERTS = '/client/:clientId/client-alerts',
  CLIENT_ALERT = '/client-alerts',
  // HOUSEHOLD DETAILS
  HOUSEHOLD_DETAILS = '/client/:id/household-details',
  CLIENT_HOUSEHOLD_DETAILS = 'client/household-details',
  // HEADER:
  CLIENT_HEADER = '/client-header/:id',
  REFERRAL_TYPES = '/referral-types',
  REFERRAL = 'referrals',
  REFERRAL_BY_CLIENT = '/clients/:id/referrals',
  // MEDICAL:
  // MEDICATION HISTORIES
  MEDICATION_HISTORIES = '/medication-histories',
  MEDICATION_HISTORY_BY_ID = '/medication-histories/:id',
  MEDICATION_HISTORY_BY_CLIENT = '/client/:id/medication-histories',
  // SUBSTANCES_USES
  SUBSTANCES_USES_BY_CLIENT_ID = '/client/:clientId/substance-uses',
  SUBSTANCES_USES = 'substance-uses',
  // DRUG USES
  DRUG_USES = '/drugs-use',
  DRUG_USES_BY_DRUG_ID = 'drugs-use/:drugId',
  DRUG_USES_BY_CLIENT_ID = '/client/:clientId/drugs-use',
  // SUBSTANCE_USES
  SUBSTANCE_USE = '/substance-uses',
  SUBSTANCE_USE_BY_ID = 'substance-uses/:id',
  SUBSTANCE_USE_BY_CLIENT = '/client/:id/substance-uses',
  // MEDICATION INFORMATION
  MEDICATION_INFORMATION = '/client-medical-information',
  MEDICATION_INFORMATION_BY_CLIENT = '/client/:id/client-medical-information',
  MEDICATION_INFORMATION_BY_ID = '/client-medical-information/:id',

  // MENTAL HEALTH:
  // ELIGIBILITY INFO
  ELIGIBILITY_INFO = '/eligibility-informations',
  ELIGIBILITY_INFO_BY_CLIENT = '/client/:id/eligibility-informations',

  // SICK CALLS
  CHRONOLOGICAL_NOTES = '/chronological-notes',
  CHRONOLOGICAL_NOTES_BY_ID = '/chronological-notes/:id',
  CHRONOLOGICAL_NOTES_BY_CLIENT = '/client/:id/chronological-notes',
  // SICK CALLS
  SICK_CALLS = '/sick-calls',
  SICK_CALLS_BY_ID = '/sick-calls/:id',
  SICK_CALLS_BY_CLIENT = '/client/:id/sick-calls',
  // NURSING NOTES
  NURSING_NOTES = '/nursing-notes',
  NURSING_NOTES_BY_ID = '/nursing-notes/:id',
  NURSING_NOTES_BY_CLIENT = '/client/:id/nursing-notes',
  // EDUCATION RECORD
  EDUCATION_RECORD_BY_CLIENT = '/client/:id/health-education-records',
  EDUCATION_RECORD = '/health-education-records',
  // MEDICAL DIAGNOSIS
  MEDICAL_DIAGNOSIS = '/medical-diagnosis',
  MEDICAL_DIAGNOSIS_BY_ID = '/medical-diagnosis/:id',
  MEDICAL_DIAGNOSIS_BY_CLIENT = '/client/:id/medical-diagnosis',
  // MEDICAL DIAGNOSIS
  IMMUNIZATION = '/immunizations',
  IMMUNIZATION_BY_ID = '/immunizations/:id',
  IMMUNIZATION_BY_CLIENT = '/clients/:id/immunizations',
  // EXEMPTIONS
  EXEMPTIONS = '/exemptions',
  // VACCINATIONS
  VACCINATIONS = '/vaccinations',
  // DIAGNOSIS CODES
  MEDICAL_DIAGNOSIS_CODES = '/medical-diagnosis-codes',
  // PERIODS
  PERIODS_BY_ACADEMIC_YEAR = '/academic-years/:id/period',
  // JOB TITLES
  JOB_TITLES_BY_CATEGORY_ID = '/job-category/:id/job-titles',
  JOB_TITLES_BY_AGENCY = '/agency/:id/job-titles',
  JOB_TITLES = '/job-titles',
  // Comments And Signatures
  TRANSITION_COMMENTS = '/transition-comments',
  TRANSITION_COMMENTS_BY_ID = '/transition-comments/:id',
  TRANSITION_COMMENTS_BY_CLIENT = '/client/:id/transition-comments',
  // Transition Planning
  TRANSITION_PLANNING = '/transition-planning',
  TRANSITION_PLANNING_BY_ID = '/transition-planning/:id',
  TRANSITION_PLANNING_BY_CLIENT = '/client/:id/transition-planning',
  TRANSITION_DETAILS_GOALS_BY_CLIENT = '/client/treatment-planning/:id/goals',
  // TRANSITION SIGNATURES
  TRANSITION_SIGNATURES = '/transition-signatures',
  // EMAR
  EMAR_BY_CLIENT = '/clients/:id/medication-administration-records',
  EMAR = '/medication-administration-records',
  EMAR_DOSAGE = '/medication-administration-dosages',
  // EXIT PLAN
  EXIT_PLAN_BY_CLIENT = '/client/:id/exit-plans',
  EXIT_PLAN = '/exit-plans',
  // AFTERCARE REFERRALS
  AFTERCARE_REFERRALS = '/aftercare-referrals',
  AFTERCARE_REFERRALS_BY_ID = '/aftercare-referrals/:id',
  AFTERCARE_REFERRALS_BY_CLIENT = '/client/:id/aftercare-referrals',
  // AFTERCARE APPOINTMENTS
  AFTERCARE_APPOINTMENTS = '/aftercare-appointments',
  AFTERCARE_APPOINTMENTS_BY_ID = '/aftercare-appointments/:id',
  AFTERCARE_APPOINTMENTS_BY_CLIENT = '/client/:id/aftercare-appointments',
  // INTRA AGENCY TRANSFERS
  INTRA_AGENCY_TRANSFERS = '/agencies/:id/intra-agency-transfers',
  INTRA_AGENCY_TRANSFERS_APPROVE = '/intra-agency-transfers/approve',
  INTRA_AGENCY_TRANSFERS_DECLINE = '/intra-agency-transfers/decline',
  // SERVICE GAP REPORT
  SERVICE_GAP_REPORT = '/reports/service-gap-report',
  CENTER_SERVICE_GAP_REPORT = '/reports/center/:id/service-gap-report',
  // SERVICE LIST REPORT
  SERVICE_LIST_REPORT = '/reports/service-list-report',
  // SERVICE LIST REPORT
  SERVED_REPORT = '/reports/client-served-report',
  SERVED_REPORT_BY_CLIENT = '/reports/center/:id/client-served-report',
  // TREATMENT PLANNING
  TREATMENT_PLANNING = '/client/treatment-planning',
  TREATMENT_PLANNING_BY_ID = '/client/:id/treatment-planning/:treatmentId',
  TREATMENT_PLANNING_BY_CLIENT = '/client/:id/treatment-planning-list',
  // TREATMENT PLANNING GOALS
  GOALS = '/client/treatment-planning/goal',
  GOALS_BY_ID = '/client/treatment-planning/goal/:goalId',
  GOALS_BY_TREATMENT_ID = '/client/treatment-planning/:treatmentId/goals',
  // TREATMENT PLANNING ACTION ITEMS
  ACTION_ITEMS = '/client/treatment-planning/goal/action-item',
  ACTION_ITEMS_CLOSE = '/client/treatment-planning/goal/action-item/:actionId/close',
  ACTION_ITEMS_BY_GOAL_ID = '/client/treatment-planning/goal/:id/action-items',
  // GROWTH AND CHANGE
  GROWTH_AND_CHANGE = '/client/growth-and-change',
  GROWTH_AND_CHANGE_BY_ID = '/client/growth-and-change/:id',
  GROWTH_AND_CHANGE_BY_CLIENT = '/client/:id/growth-and-change',
  // AFTERCARE APPOINTMENTS
  SERVICE_LIST = '/client/service-list',
  SERVICE_LIST_BY_ID = '/client/service-list/:id',
  SERVICE_LIST_BY_CLIENT = '/client/:id/service-list',
  // INCIDENT REPORTINGS
  INCIDENT_REPORTINGS_BY_AGENCY = '/agency/:id/incident-reports',
  INCIDENT_REPORTINGS_BY_CENTER = '/center/:id/incident-reports',
  INCIDENT_REPORTINGS_BY_CLIENT = '/client/:id/incident-reports',
  // INCIDENT REPORTS BY CENTER
  INCIDENT_REPORT_DETAIL = '/client/incident-reports',
  // INCIDENT REPORT BY ID
  INCIDENT_BY_ID = 'client/incident-reports/:id',
  // INCIDENT REPORT - CLIENT INVOLVE BY ID
  INCIDENT_CLIENTS_INVOLVED_INFO_BY_ID = 'client/incident-reports/clients-involved/:id',
  INCIDENT_CLIENTS_INVOLVED_BY_INCIDENT_ID = 'client/incident-reports/:id/clients-involved',
  INCIDET_CLIENTS_INVOLVED_FORM = 'client/incident-reports/client-involved',
  // INCIDENT REPORT - EMPLOYEE INVOLVE BY ID
  INCIDENT_EMPLOYEE_INVOLVED_INFO_BY_ID = 'client/incident-reports/employee-involved/:id',
  // REVIEW THIS >
  INCIDENT_EMPLOYEE_INVOLVED_BY_INCIDENT_ID = 'client/incident-reports/:id/employee-involved',
  INCIDET_EMPLOYEE_INVOLVED_FORM = 'client/incident-reports/employee-involved',
  // ONBOARDING
  ONBOARDING = '/client/onboarding',
  CLIENT_WAITLIST = '/center/:centerId/waitlist',
  // AGENCY
  AGENCY_BY_ID = '/agency/:id',
  AGENCY_PHOTO = '/agency/:id/avatar',
  // TRAINING PLANS
  TRAINING_PLAN = '/training-plans',
  TRAINING_PLAN_BY_ID = '/training-plans/:id',
  TRAINING_PLAN_BY_AGENCY = '/agencies/:id/training-plans',
  TRAINING_PLAN_ASSIGN = '/training-plans/assign',
  // LICENSE/CERTIFICATION
  LICENSE_CERTIFICATION = '/license-certifications',
  LICENSE_CERTIFICATION_BY_ID = '/license-certifications/:id',
  LICENSE_CERTIFICATION_BY_STAFF = '/staff/:id/license-certifications',
  // STAFF MEMBER TRAININGS
  UPCOMING_COURSES = '/upcoming-courses/complete',
  UPCOMING_COURSES_BY_STAFF = '/staff-members/:id/transcripts',
  // LICENSE/CERTIFICATION
  UPCOMING_COURSE = '/upcoming-courses/complete',
  UPCOMING_COURSE_BY_STAFF = '/staff-members/:id/upcoming-courses',
  // ROLES
  ROLES = '/roles',
  ROLES_BY_ID = '/roles/:role',
  ROLES_BY_AGENCY = '/agency/:id/roles',
  ALL_ROLES_BY_AGENCY = '/agency/:id/all-roles',
  // Permissions
  PERMISSIONS = '/Permissions',
  ASSIGN_PERMISSIONS = '/roles/permissions',
  // PREVIOUS PACE ENROLLMENT
  PREVIOUS_ENROLLMENTS = '/client/previous-enrollment',
  PREVIOUS_ENROLLMENTS_BY_CLIENT = '/client/:id/previous-enrollment',
  // ASSESSMENTS CATALOG
  CLIENT_ASSESSMENT = 'client-assessments/:id',
  ASSESSMENTS_CATALOG = 'assessment-catalogs',
  ASSESSMENTS_AVAILABLES = 'assessments/filtered',
  ELA_COMMON = 'assessments/client/ELA',
  ELA_COMMON_BY_ID = 'assessments/ELA/:id',
  MATH_COMMON = 'assessments/client/MCA',
  MATH_COMMON_BY_ID = 'assessments/MCA/:id',
  LEGAL_ASSESSMENT = 'assessments/client/legal',
  LEGAL_ASSESSMENT_BY_ID = 'assessments/legal/:id',
  PONA = 'assessments/client/PONA',
  PONA_BY_ID = 'assessments/PONA/:id',
  START_ASSESSMENT = 'assessments/client/STAR',
  START_ASSESSMENT_BY_ID = 'assessments/STAR/:id',
  // ATTENDANCE
  ATTENDANCE = 'client-course-attendance',
  ATTENDANCE_BY_CLIENT = '/clients/:id/client-course-attendance',
  ATTENDANCE_BY_SEMESTER = '/clients/:id/semesters/:semesterId/client-course-attendance',
  // CONSENTS FORMS
  CONSENTS_BY_CLIENT = 'clients/:id/consent-forms',
  // ROSTER
  CENTER_ROSTER = '/global-rosters/center/:centerId',
  ROSTER_COURSE_ATTENDANCE = '/roster-course-attendance',
  ROSTER_BY_ID = '/global-rosters/:id',
  // TASKS
  CENTER_COURSES = '/centers/:id/courses',
  // INTAKE ENROLLMENT
  INTAKE_ENROLLMENT_BY_CLIENT = 'client/:id/intake-enrollment',
  // TASKS
  TASKS = 'v1/Tasks',
  // QUALITY DASHBOARD
  COMPLIANCE_DASHBOARD_CENTERS = 'compliance-dashboard-centers',
  ENROLLMENT_STATUS = 'compliance-dashboard/enrollment-status',
  COMPLETE_TASK = 'v1/Tasks/Complete/:id',
  // HELP-DESK
  TICKETSBYSTAFFMEMBER = '/staff-member/my-tickets',
  TICKET_DETAIL = '/staff-members/help-desk-ticket/:id',
  ADDTICKET = '/staff-members/help-desk-ticket',
  // HELP-DESK-ADMIN
  ASSIGNED_TICKETS = '/staff-members/help-desk-tickets',
  // HELP-DESK-TICKET-COMMENT
  COMMENTS_BY_TICKET = '/help-desk-tickets/:id/comments',
  ADD_COMMENT = '/help-desk-tickets/comment',
  // PROVIDED-SERVICES-TYPE
  SERVICES_TYPE = '/provided-services-types',
  // NOTIFICATIONS
  NOTIFICATIONS = 'v1/Notifications',
  READ_NOTIFICATION = 'v1/Notifications/read/:id',
  READ_ALL_NOTIFICATIONS = 'v1/Notifications/dismissall',
}
