import { chipClasses } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { blue, dark, green, grey, light, purple } from './palette';
import { BLACK, BLUE, GREY, TRANSPARENT, WHITE } from './palette/new';

declare module '@mui/material/styles' {
  interface Palette {
    darker: string;
    customGrey: string;
  }
  interface PaletteOptions {
    darker: string;
    customGrey: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primarybtn: true;
    secondarybtn: true;
    tertiarybtn: true;
    tertiarybtnSmall: true;
    PACE_Secondary: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    customGrey: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsColorOverrides {
    darker: true;
    customGrey: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    primarychip: true;
    secondarychip: true;
  }
}

const theme = createTheme({
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: 'primarychip' },
          style: {
            backgroundColor: blue[200],
            borderRadius: '30px',
            height: '40px',
            color: light[100],
            width: '98px',
            cursor: 'pointer',
          },
        },
        {
          props: { variant: 'secondarychip' },
          style: {
            backgroundColor: purple[100],
            borderRadius: '30px',
            height: '40px',
            cursor: 'pointer',
            color: light[100],
            [`& .${chipClasses.icon}`]: {
              color: light[100],
            },
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: '35px',
          height: '35px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: dark[200],
          fontSize: '0.875rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
      variants: [
        {
          props: { variant: 'primarybtn' },
          style: {
            fontSize: '16px',
            textTransform: 'capitalize',
            height: '48px',
            borderRadius: '48px',
            backgroundColor: BLUE[400],
            color: WHITE[100],
            ':hover': {
              backgroundColor: BLUE[399],
            },
            ':disabled': {
              backgroundColor: GREY[100],
              color: GREY[400],
            },
          },
        },
        {
          props: { variant: 'secondarybtn' },
          style: {
            fontSize: '16px',
            textTransform: 'capitalize',
            height: '48px',
            borderRadius: '48px',
            backgroundColor: WHITE[200],
            border: `1px solid ${BLUE[600]}`,
            color: GREY[800],
            ':hover': {
              backgroundColor: BLUE[299],
            },
            ':disabled': {
              backgroundColor: WHITE[100],
              border: `1px solid ${GREY[200]}`,
              color: GREY[200],
            },
          },
        },
        {
          props: { variant: 'tertiarybtn' },
          style: {
            fontSize: '16px',
            textTransform: 'capitalize',
            height: '48px',
            borderRadius: '48px',
            backgroundColor: TRANSPARENT,
            border: 'noen',
            textDecoration: 'underline',
            color: GREY[800],
            ':hover': {
              color: BLUE[600],
              backgroundColor: TRANSPARENT,
              textDecoration: 'underline',
            },
            ':disabled': {
              color: GREY[200],
            },
          },
        },
        {
          props: { variant: 'tertiarybtnSmall' },
          style: {
            fontSize: '12px',
            textTransform: 'capitalize',
            height: '30px',
            borderRadius: '30px',
            backgroundColor: TRANSPARENT,
            border: 'noen',
            textDecoration: 'underline',
            color: GREY[800],
            ':hover': {
              color: BLUE[600],
              backgroundColor: TRANSPARENT,
              textDecoration: 'underline',
            },
            ':disabled': {
              color: GREY[200],
            },
          },
        },
        {
          props: { variant: 'PACE_Secondary' },
          style: {
            fontSize: '16px',
            height: '48px',
            borderRadius: '48px',
            color: BLACK[100],
            border: `1px solid ${BLUE[600]}`,
            textTransform: 'initial',
            [':hover']: {
              backgroundColor: BLUE[600],
              color: WHITE[200],
            },
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: purple[100],
    },
    secondary: {
      main: grey[500],
    },
    success: {
      main: green[200],
    },
    darker: dark[200],
    customGrey: '#97999B',
  },
  typography: {
    fontFamily: 'Outfit',
  },
});

export default theme;
