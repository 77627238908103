import { Badge, Box } from '@mui/material';

type Props = {
  text: string;
  color: string;
  dotColor?: string;
  isStatus?: boolean;
};

const StatusChip: React.FC<Props> = (props: Props) => {
  const { text, color, dotColor, isStatus } = props;
  return (
    <Box
      component={'span'}
      sx={{
        height: '25px',
        maxHeight: '40px',
        backgroundColor: color,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: isStatus ? '25px' : '4px',
        padding: '0 10px',
        fontSize: '13px',
      }}
    >
      {isStatus && (
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: dotColor,
            },
            marginRight: '8px',
          }}
          variant='dot'
        />
      )}
      {text}
    </Box>
  );
};

export default StatusChip;
