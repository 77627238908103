import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, Button, Grid } from '@mui/material';
import { useDateFormatter } from '@react-aria/i18n';
import { H4, Paragraph } from 'components/Text';
import { FontWeight } from 'components/Text/BaseText';
import { useState } from 'react';
import { BLACK, GREY, WHITE } from 'theme/palette/new';
export const MonthPicker = ({
  minDate,
  maxDate,
  onSelect,
  field,
}: {
  minDate: any;
  maxDate: any;
  onSelect: (value: any) => () => void;
  field: string;
}) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const formatter = useDateFormatter({ month: 'short' });

  const months = Array.from({ length: 12 }, (_, i) => new Date(selectedYear, i));

  const previousYear = () => {
    setSelectedYear((prev) => prev - 1);
  };

  const nextYear = () => {
    setSelectedYear((prev) => prev + 1);
  };

  return (
    <Box sx={{ width: 'auto', margin: '0 auto' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 2,
          height: 49,
          backgroundColor: WHITE[200],
        }}
      >
        <Button onClick={previousYear} disabled={minDate && selectedYear <= minDate.getFullYear()}>
          <ArrowLeftIcon sx={{ color: BLACK[100] }} />
        </Button>
        <H4 fontWeight={FontWeight.medium} color={GREY[600]}>
          {selectedYear}
        </H4>
        <Button onClick={nextYear} disabled={maxDate && selectedYear >= maxDate.getFullYear()}>
          <ArrowRightIcon sx={{ color: BLACK[100] }} />
        </Button>
      </Box>
      <Grid container spacing={2} px={1}>
        {months.map((month, index) => (
          <Grid item xs={4} key={index}>
            <Box
              sx={{
                padding: 2,
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: WHITE[100],
                },
              }}
              onClick={onSelect({
                field,
                value: month,
                label: `${formatter.format(month)} ${selectedYear}`,
              })}
            >
              <Paragraph fontWeight={FontWeight.medium} color={GREY[600]}>
                {formatter.format(month).toUpperCase()}
              </Paragraph>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
