import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { H5, Paragraph } from 'components/Text';
import { FontWeight } from 'components/Text/BaseText';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BLACK, BLUE } from 'theme/palette/new';
import { filterOptionsStyle, filterStyle } from 'theme/styles/components/filter';
import { MonthPicker } from './calendar';

interface Option {
  id: number;
  label: string;
}

export const FilterBy = ({ fieldName, options }: { fieldName: string; options: any }) => {
  const { setValue, watch } = useFormContext();
  const buttonRef = useRef<any>(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentOptions, setCurrentOptions] = useState(options);
  const [multipleParams, setMultipleParams] = useState<Option[]>([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (option) => () => {
    setValue(fieldName, option);
    handleClose();
    setTimeout(() => {
      setCurrentOptions(options);
    }, 300);
  };

  const onSelectMultipleParams = () => {
    handleClose();
    setTimeout(() => {
      setCurrentOptions(options);
    }, 300);
  };

  const onSelectMultipleOptions = (option) => {
    setMultipleParams((prevParams) => [...prevParams, option]);
  };

  const clearMultipleOptions = () => setMultipleParams([]);

  const clear = () => {
    setValue(fieldName, undefined);
    clearMultipleOptions();
  };

  const closeIconRotate = anchorEl ? '180deg' : '0deg';
  const selected = watch(fieldName);
  const hasFilter = !!selected;

  const getFilterText = (hasFilter, selected) => {
    if (!hasFilter) {
      return 'Filter By';
    }

    if (Array.isArray(selected)) {
      const labels = selected.map((item) => item.label).join(' - ');
      return `Filter: ${labels}`;
    }

    return `Filter: ${selected.label}`;
  };

  useEffect(() => {
    if (multipleParams.length > 1) {
      setValue(fieldName, multipleParams);
    }
  }, [multipleParams, setValue, fieldName]);

  const renderOptions = (option) => {
    if (option.type === 'main')
      return option.options.map((option, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            setCurrentOptions(option);
          }}
        >
          <Paragraph fontWeight={FontWeight.regular}>{option.label}</Paragraph>
          <ChevronRightIcon sx={{ ml: 'auto' }} />
        </MenuItem>
      ));
    if (option.type === 'text')
      return option.options.map((o, index) => (
        <MenuItem
          key={index}
          onClick={onSelect({ field: option.field, value: o.id, label: o.label })}
        >
          <Paragraph fontWeight={FontWeight.regular}>{o.label}</Paragraph>
        </MenuItem>
      ));
    if (option.type === 'calendar')
      return (
        <MonthPicker
          minDate={option.minDate}
          maxDate={option.maxDate}
          onSelect={onSelect}
          field={option.field}
        />
      );
    if (option.type === 'textAndSubMenu')
      return option.options.map((o, index) => {
        if (o?.options) {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                setCurrentOptions(o);
                clearMultipleOptions();
                onSelectMultipleOptions({ field: option.field, value: o.id, label: o.label });
              }}
            >
              <Paragraph fontWeight={FontWeight.regular}>{o.label}</Paragraph>
              <ChevronRightIcon sx={{ ml: 'auto' }} />
            </MenuItem>
          );
        }
        return (
          <MenuItem
            key={index}
            onClick={onSelect({ field: option.field, value: o.id, label: o.label })}
          >
            <Paragraph fontWeight={FontWeight.regular}>{o.label}</Paragraph>
          </MenuItem>
        );
      });

    if (option.type === 'nestedText')
      return option.options.map((o, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            onSelectMultipleOptions({ field: option.field, value: o.id, label: o.label });
            onSelectMultipleParams();
          }}
        >
          <Paragraph fontWeight={FontWeight.regular}>{o.label}</Paragraph>
        </MenuItem>
      ));
  };

  return (
    <Fragment>
      <Box
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        sx={filterStyle}
        ref={buttonRef}
      >
        <FilterListIcon />
        <Typography ml={1} color={hasFilter ? BLUE[600] : BLACK[100]}>
          {getFilterText(hasFilter, selected)}
        </Typography>
        {hasFilter ? (
          <IconButton sx={{ ml: 'auto' }} onClick={clear}>
            <CloseIcon sx={{ color: BLUE[600] }} />
          </IconButton>
        ) : (
          <ExpandMoreIcon sx={{ ml: 'auto', transform: `rotate(${closeIconRotate})` }} />
        )}
      </Box>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            width: buttonRef.current ? buttonRef.current.clientWidth : 'auto',
          },
        }}
        sx={filterOptionsStyle}
        className='filterBy'
      >
        {currentOptions.type !== 'main' && (
          <MenuItem
            onClick={() => {
              setCurrentOptions(options);
              clearMultipleOptions();
            }}
            className='back'
          >
            <ChevronLeftIcon sx={{ mr: 1 }} />
            <H5 fontWeight={FontWeight.medium} color={BLACK[100]}>
              {currentOptions.label}
            </H5>
          </MenuItem>
        )}
        {renderOptions(currentOptions)}
      </Menu>
    </Fragment>
  );
};
